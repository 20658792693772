<template>
	<highCharts
		ref="highcharts"
		class="chart"
		:options="valueAssign"
	/>
</template>

<script>
import Highcharts from 'highcharts'
import { Chart } from 'highcharts-vue'
import lodash from 'lodash'

const more = require('highcharts/highcharts-more')
more(Highcharts)

Highcharts.setOptions({
	lang: {
		loading: 'Загрузка...',
		months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
		weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
		shortMonths: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],
		rangeSelectorFrom: 'С',
		rangeSelectorTo: 'По',
		rangeSelectorZoom: 'Диапазон (мес.)'
	},
	legend: {
		enabled: true,
		layout: 'horizontal',
		verticalAlign: 'top',
		align: 'left'
	},

	global: {
		getTimezoneOffset: 0,
		useUTC: false
	},

	chart: {
		type: 'line',
		style: {
			fontFamily: 'Roboto, Arial, sans-serif',
			color: '#212121'
		},

		backgroundColor: 'rgba(255,255,255,0)'
	},

	colors: [
		'#32A4FF',
		'#FFD54F',
		'#01D358',
		'#F25449',
		'#B245F4',
		'#57E5F8',
		'#E8F472',
		'#7059FF',
		'#5F7193',
		'#FF9E2D'
	],

	tooltip: {
		animation: true,
		backgroundColor: '#fff',
		borderRadius: 4,
		borderWidth: 0,
		headerFormat: '<div style="font-size: 12px; font-weight: 500; border-bottom: 1px solid #eaeaea; padding-bottom: 8px; margin-bottom: 8px">{point.key}</div>',
		pointFormat: '<div style="line-height: 18px;"><span style="font-size: 16px; color:{point.color};">\u25CF</span> {series.name}: <b>{point.y}</b></div>',
		shared: true,
		padding: 16,
		useHTML: true,
		xDateFormat: '%e %B %Y',
		split: false
	},

	credits: {
		enabled: false
	},

	title: {
		text: null
	},

	xAxis: {
		type: 'datetime',
		tickWidth: 0,
		gridLineWidth: 0,

		dateTimeLabelFormats: {
			millisecond: '%H:%M:%S.%L',
			second: '%H:%M:%S',
			minute: '%H:%M',
			hour: '%H:%M',
			day: '%d.%m',
			week: '%d.%m',
			month: '%b \'%y',
			year: '%Y'
		},

		labels: {
			style: {
				color: '#bdbdbd',
				fontSize: '12px'
			}
		},

		crosshair: {
			width: 1,
			color: '#AAAAAA'
		}
	},

	yAxis: {
		opposite: false,
		title: { text: null },
		min: 0,
		labels: {
			align: 'right',
			style: {
				color: '#bdbdbd',
				fontSize: '12px'
			}
		}
	},

	plotOptions: {
		series: {
			turboThreshold: 10000,
			marker: {
				enabled: false
			},
			lineWidth: 2
		}
	}
})

export default {
	props: {
		value: {
			type: Object
		}
	},

	data () {
		return {
			interValue: {},
			isMounted: false,
			container: null,
			resizeObserver: null
		}
	},

	components: {
		highCharts: Chart
	},

	computed: {
		valueAssign () {
			let setting = {
				series: [],
				chart: {}
			}

			const valueSettings = lodash.cloneDeep(this.interValue)

			if (!valueSettings.series) {
				valueSettings.series = []
			}

			setting = Object.assign(setting, valueSettings)

			return setting
		},

		chartLoaded () {
			if (!this.isMounted) return null
			return this.$refs.highcharts && this.$refs.highcharts.chart ? !!this.$refs.highcharts.chart : null
		}
	},

	watch: {
		value: {
			handler () {
				this.$set(this, 'interValue', lodash.cloneDeep(this.value))
			},
			deep: true
		},

		async chartLoaded (val) {
			if (val) {
				await this.$nextTick(() => {})
			}
		}
	},

	created () {
		this.$set(this, 'interValue', lodash.cloneDeep(this.value))
		this.container = document.querySelector('.v-main__wrap > .container')
	},

	mounted () {
		this.isMounted = true
		this.setResizeObserver()
	},

	beforeDestroy () {
		if (this.resizeObserver) {
			this.resizeObserver.disconnect()
		}
	},

	methods: {
		setResizeObserver () {
			this.resizeObserver = new ResizeObserver(() => {
				const highcharts = this.$refs.highcharts && this.$refs.highcharts.chart
				this.$nextTick(() => {
					if (highcharts?.reflow) {
						highcharts.reflow()
					}
				})
			})

			if (this.container) {
				this.resizeObserver.observe(this.container)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.chart {
	width: 100%;
	position: relative;
}
</style>

<style lang="scss">
.chart .highcharts {
  &-tooltip {
    background-color: rgba(255,255,255,1);
    z-index: 2;

    span {
      z-index: 2;
    }
  }

  &-loading {
    right: 0 !important;
    left: 0 !important;
    width: auto !important;
    height: 4px !important;
    margin-top: -1px;
    border-radius: 100px !important;
    opacity: 1 !important;
    z-index: -1 !important;
    background-color: #D4ECFF !important;

    &-inner {
      display: block;
      width: 100%;
      height: 100%;
      margin-top: -1px;
      border-radius: 100px;
      text-indent: -9999em;
      background-color: #2979FF;
      animation: indeterminateAnimation 1s infinite linear;
      transform-origin: 0 50%;
    }
  }
}

@keyframes indeterminateAnimation {
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(.4);
  }
  100% {
    transform: translateX(100%) scaleX(.5);
  }
}
</style>
